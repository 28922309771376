import { useContext } from "react";
import { PanelContext } from "../../../../../../../context/PanelContext";
import { FaSearchPlus } from "react-icons/fa";
import { Caminho } from "../../../../../../../assets/images";
import { Carousel } from "react-responsive-carousel";
import ReactLoading from "react-loading";

const CardImage = () => {
  
  const {
    fullSizeImages,
    isLoading,
    setViewImage,
    disableDragging,
    selectedImageIndex,
    setSelectedImageIndex,
    popupImages
  } = useContext(PanelContext);

  const carouselProps = {
    selectedItem: selectedImageIndex,
    onChange: (index) => {
      setSelectedImageIndex(index);
    },
    onClick: (e) => e.stopPropagation(),
    key: popupImages.join(","),
    showArrows: true,
    style: { cursor: "pointer" },
    renderArrowPrev: (onClickHandler, hasPrev, label) =>
      hasPrev && (
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onClickHandler(e);
          }}
          title={label}
          className="carousel-arrow carousel-arrow-prev"
        >
          {"<"}
        </button>
      ),
    renderArrowNext: (onClickHandler, hasNext, label) =>
      hasNext && (
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onClickHandler(e);
          }}
          title={label}
          className="carousel-arrow carousel-arrow-next"
        >
          {">"}
        </button>
      ),
    showStatus: false,
    showThumbs: false,
    showIndicators: true,
    dynamicHeight: true
  };
  return (
    <div className="imagem">
      {fullSizeImages.length > 0 && (
        <button
          className="zoom-button"
          onClick={() => {
            if (fullSizeImages[selectedImageIndex]) {
              setViewImage(fullSizeImages[selectedImageIndex]);
              disableDragging();
            }
          }}
        >
          <FaSearchPlus />
        </button>
      )}
      {isLoading ? (
        <div className="spinner-container">
          <ReactLoading type="spin" color="#000" height={50} width={50} />
        </div>
      ) : (
        <>
          {popupImages.length > 0 ? (
            <Carousel {...carouselProps}>
              {popupImages.map((imageUrl, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <img
                    src={imageUrl}
                    alt={`boi-${index}`}
                    style={{ width: 312, height: 208 }}
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <img
              src={Caminho}
              alt="caminhos"
              style={{
                width: 312,
                height: 208,
                opacity: "0.7",
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CardImage;