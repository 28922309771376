// hooks/useFirebaseData.js
import { useEffect, useState } from "react";
import firebaseDb from "../config/firebase";

export const useFirebaseData = () => {
  const [data, setData] = useState({});
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    const ref = firebaseDb.child("contacts");
    const unsubscribe = ref.on("value", (snapshot) => {
      const val = snapshot.val();
      if (val) {
        setData(val);
        setTotalQuantity(Object.keys(val).length);
      } else {
        setData({});
        setTotalQuantity(0);
      }
    });

    return () => {
      ref.off("value", unsubscribe);
      setData({});
      setTotalQuantity(0);
    };
  }, []);

  return { data, totalQuantity };
};