import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from "react-leaflet";
import ReactLeafletKml from "react-leaflet-kml";

function processKml(kml) {
  // Extrai informações dos marcadores aqui
  const placemarks = kml.getElementsByTagName('Placemark');
  let placemarkObjects = [];

  for (let i = 0; i < placemarks.length; i++) {
    const name = placemarks[i].getElementsByTagName('name')[0].textContent;
    const description = placemarks[i].getElementsByTagName('description')[0]?.textContent;
    const coordinates = placemarks[i].getElementsByTagName('coordinates')[0].textContent;

    // Dividir as coordenadas por ',' para obter lat e lon separadamente
    const [lon, lat] = coordinates.split(',');

    // Criar um novo objeto para cada placemark
    const placemarkObject = {
      name: name,
      description: description,
      coordinates: {
        lat: parseFloat(lat),
        lon: parseFloat(lon)
      }
    };

    // Adicionar o novo objeto ao array
    placemarkObjects.push(placemarkObject);
  }

  // Retornar o array de objetos
  console.log(placemarkObjects);
  return placemarkObjects;
}

function MapNew() {
  const [kml, setKml] = useState(null);

  useEffect(() => {
    fetch('./mapa.kml')
      .then(res => res.text())
      .then(kmlText => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, "text/xml");
        setKml(kml);
        processKml(kml);
      });
  }, []);

  return (
    <div>
      <MapContainer
        style={{ height: "100vh", width: "100%" }}
        center={[-2.5483629, -44.1899462]}
        zoom={11.5}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {kml && <ReactLeafletKml kml={kml} />}
      </MapContainer>
    </div>
  );
}

export default MapNew;
