import React from 'react';
import './styles.css';

const Card = ({ imageSrc, nomePessoa, tituloPessoa, link }) => {
  return (
    <div className='card-container'>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={imageSrc} height={80} width={80} className='avatar-image' alt='avatar'>
          </img>
          <span className='card-titulo'>
            {tituloPessoa}
          </span>
          <p className='card-nome'>
            {nomePessoa}
          </p>
        </a>

      ) : (
        <div style={{textDecoration: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={imageSrc} height={80} width={80} className='avatar-image' alt='avatar'>
          </img>
          <span className='card-titulo'>
            {tituloPessoa}
          </span>
          <p className='card-nome'>
            {nomePessoa}
          </p>
        </div>

      )
      }
    </div >
  )
}

export default Card