import React, { useContext } from "react";
import { PanelContext } from "../../../../context/PanelContext.jsx";
import { Marker, Tooltip } from "react-leaflet";
import { Icon } from "leaflet";
import { defaultStyle, sotaqueStyles } from "../../../../data.js";
import CustomPopup from "./components/CustomPopup.jsx";

const MapMarker = ({ id }) => {
  const { data, hoveredMarker, setOpenedPopupId, fetchImages, markerRefs } =
    useContext(PanelContext);
  const style = sotaqueStyles[data[id].sotaque] || defaultStyle;
  let { colorIcon } = style;

  const icon = new Icon({
    iconUrl: colorIcon,
    iconSize: id === hoveredMarker ? [40, 40] : [36, 36],
  });

  const markerClass = `my-tooltip ${
    data[id].sotaque === "Zabumba" || data[id].sotaque === "Costa de Mão"
      ? "black"
      : ""
  }`;

  return (
    <Marker
      id={id}
      key={id}
      ref={(marker) => {
        if (marker) {
          markerRefs.current[id] = marker;
        }
      }}
      position={[data[id].latitude, data[id].longitude]}
      icon={icon}
      eventHandlers={{
        popupopen: () => {
          setOpenedPopupId(id);
          fetchImages(data[id].codigo);
        },
        popupclose: () => setOpenedPopupId(null),
      }}
      Tooltip={Tooltip}
      title={data[id].name}
    >
      <CustomPopup id={id} />
      <Tooltip
        className={markerClass}
        permanent={true}
        direction="top"
        offset={[0, 5]}
      >
        {data[id].codigo}
      </Tooltip>
    </Marker>
  );
};

export default MapMarker;