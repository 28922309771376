import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./context/AuthContext";
import { PanelContext } from "./context/PanelContext";
import { Map, Login, AddEdit, Home, AdminPage, MapNew, Header } from "./pages";

function App() {
  const { currentUser } = useContext(AuthContext);
  const { handleToggleConfetti } = useContext(PanelContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer position="top-center" />
        <Header />
        <button className="toggle-confetti" onClick={handleToggleConfetti}>
          🎊
        </button>
        <Routes>
          <Route path="/" element={<Map />}
          />
          <Route path="login" element={<Login />} />
          <Route path="map" element={<Map />}
          />
          <Route path="mapnew" element={<MapNew />} />
          <Route
            path="add"
            element={
              <RequireAuth>
                <AddEdit />
              </RequireAuth>
            }
          />
          <Route
            path="update/:id"
            element={
              <RequireAuth>
                <AddEdit />
              </RequireAuth>
            }
          />
          <Route
            path="cadastro"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/admin"
            element={
              <RequireAuth>
                <AdminPage />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;