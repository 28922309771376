import React, { createContext, useState, useContext, useCallback, useRef, useMemo } from 'react';
import firebase from "firebase/compat/app";
import { Caminho } from '../assets/images';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { removeAccents } from '../utils';

export const PanelContext = createContext();

export const usePanel = () => useContext(PanelContext);

export const PanelProvider = ({ children }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showCollaborate, setShowCollaborate] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);
  const [animateOx, setAnimateOx] = useState(true);
  const [numberOfPieces, setNumberOfPieces] = useState(200);
  const [location, setLocation] = useState({ latitude: "", longitude: "" });
  const [manualLocation, setManualLocation] = useState(false);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [openedPopupId, setOpenedPopupId] = useState(null);
  const mapRef = useRef(null);
  const markerRefs = useRef({});
  const [headerOffset, setHeaderOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSotaques, setSelectedSotaques] = useState({
    Matraca: true,
    Baixada: true,
    "Costa de Mão": true,
    Orquestra: true,
    Alternativo: true,
    Zabumba: true,
  });

  const [imagePreviewUrls, setImagePreviewUrls] = useState(
    Array(10).fill(null)
  );
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [popupImages, setPopupImages] = useState([]);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [viewImage, setViewImage] = useState(null);
  const [fullSizeImages, setFullSizeImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showFooter, setShowFooter] = useState(true);

  const { data, totalQuantity } = useFirebaseData();

  const filteredMarkers = useMemo(() => {
    return Object.keys(data).filter((id) => {
      const nameWithoutAccents = removeAccents(data[id].name.toLowerCase());
      const searchTermWithoutAccents = removeAccents(searchTerm.toLowerCase());
  
      return (
        (!selectedSotaques || selectedSotaques[data[id].sotaque]) &&
        (!searchTerm || nameWithoutAccents.includes(searchTermWithoutAccents))
      );
    });
  }, [data, selectedSotaques, searchTerm]);
  

  const handleShowFooter = () => {
    setShowFooter(!showFooter);
  };

   const fetchImages = useCallback(async (codigo) => {
    setIsLoading(true); // Set loading state to true
    setPopupImages([Caminho]); // Set the default image
    setFullSizeImages([]); // Initialize full size images

    const storageRef = firebase.storage().ref();
    const imagesRef = storageRef.child(`images/${codigo}`);

    try {
      const res = await imagesRef.listAll();
      const imageURLsPromises = {};
      const fullSizeImageURLsPromises = {};

      for (const item of res.items) {
        if (item.name.startsWith(`${codigo}-`)) {
          const urlPromise = item.getDownloadURL(); // Get promise for image URL
          const imageName = item.name.replace(
            /(-small|-thumb)\.webp$/,
            ".webp"
          );

          if (item.name.endsWith("-small.webp")) {
            imageURLsPromises[imageName] = urlPromise;
          } else if (!item.name.endsWith("-thumb.webp")) {
            fullSizeImageURLsPromises[imageName] = urlPromise;
            if (!imageURLsPromises[imageName]) {
              imageURLsPromises[imageName] = urlPromise;
            }
          }
        }
      }

      // Fetch all small image URLs in parallel
      const imageURLs = await Promise.all(Object.values(imageURLsPromises));

      // Fetch all full-size image URLs in parallel
      const fullSizeImageURLs = await Promise.all(
        Object.values(fullSizeImageURLsPromises)
      );

      setPopupImages(imageURLs);
      setFullSizeImages(fullSizeImageURLs);
    } catch (error) {
      console.error("Error retrieving image URLs:", error);
    }

    setIsLoading(false); // Set loading state to false
    setSelectedImageIndex(0); // Reset image index after images are fetched
  }, []);


  const handleToggleConfetti = () => {
    if (showConfetti) {
      setNumberOfPieces(0);
      setAnimateOx(!animateOx);
      setTimeout(() => {
        setShowConfetti(!showConfetti);
      }, 7000);
    } else {
      setShowConfetti(!showConfetti);
      setNumberOfPieces(200);
      setAnimateOx(!animateOx);
    }
  };

    // Function to enable dragging
    const enableDragging = () => {
      if (mapRef.current) {
        mapRef.current.dragging.enable();
        mapRef.current.doubleClickZoom.enable();
      }
    };
  
    // Function to disable dragging
    const disableDragging = () => {
      if (mapRef.current) {
        mapRef.current.dragging.disable();
        mapRef.current.doubleClickZoom.disable();
      }
    };

  const handleSotaqueButtonClick = (sotaque) => {
    setSelectedSotaques((prevState) => ({
      ...prevState,
      [sotaque]: !prevState[sotaque],
    }));
  };

  const handleOpenPanel = (panelName) => {
    switch (panelName) {
      case 'markerList':
        setShowList(true);
        break;
      case 'collaborate':
        setShowCollaborate(true);
        break;
      case 'info':
        setShowInfo(true);
        break;
      case 'sotaqueFilter':
        setShowFilter(true);
        break;
      default:
        break;
    }
  };

  const handleClosePanel = (panelName) => {
    switch (panelName) {
      case 'markerList':
        setShowList(false);
        break;
      case 'collaborate':
        setShowCollaborate(false);
        break;
      case 'info':
        setShowInfo(false);
        break;
      case 'sotaqueFilter':
        setShowFilter(false);
        break;
      default:
        break;
    }
  };

  const getDisplayStyle = (isVisible) => {
    return isVisible ? "block" : "none";
  };

  const handleManualLocation = () => {
    setManualLocation(true);
  };
  
  const handleGetLocation = () => {
    setManualLocation(false);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        console.log("Error getting location:", error);
      }
    );
  };

  return (
    <PanelContext.Provider value={{
      showFilter,
      showList,
      showInfo,
      showCollaborate,
      showConfetti,
      animateOx,
      selectedSotaques,
      handleOpenPanel,
      handleClosePanel,
      handleToggleConfetti,
      setShowConfetti,
      setAnimateOx,
      setSelectedSotaques,
      handleSotaqueButtonClick,
      numberOfPieces,
      setNumberOfPieces,
      searchTerm,
      setSearchTerm,
      getDisplayStyle,
      totalQuantity,
      location,
      setLocation,
      manualLocation,
      setManualLocation,
      hoveredMarker,
      setHoveredMarker,
      openedPopupId,
      setOpenedPopupId,
      fetchImages,
      imagePreviewUrls,
      setImagePreviewUrls,
      isLoading,
      setIsLoading,
      popupImages,
      setPopupImages,
      showAdditionalInfo,
      setShowAdditionalInfo,
      viewImage,
      setViewImage,
      fullSizeImages,
      setFullSizeImages,
      selectedImageIndex,
      setSelectedImageIndex,
      mapRef,
      markerRefs,
      handleShowFooter,
      enableDragging,
      disableDragging,
      showFooter,
      setShowFooter,
      data,
      filteredMarkers,
      headerOffset,
      setHeaderOffset,
      uploadedFiles,
      setUploadedFiles,
      handleManualLocation,
      handleGetLocation,
    }}>
      {children}
    </PanelContext.Provider>
  );
};