import React, { useContext } from 'react';
import CloseButton from './CloseButton';
import { PanelContext } from '../../../context/PanelContext';

const PanelHeader = ({ title, panelName, className }) => {
  const { handleClosePanel } = useContext(PanelContext);

  return (
    <div className={`info-container-title ${className}`}>
      <h3>{title}</h3>
      <CloseButton panelName={panelName} onClick={() => handleClosePanel(panelName)} />
    </div>
  );
};

export default PanelHeader;