import React, { useContext } from "react";
import { PanelContext } from "../../../../../../context/PanelContext";
import { defaultStyle, sotaqueStyles } from "../../../../../../data";
import CardTitle from "./components/CardTitle";
import CardImage from "./components/CardImage";
import CardButtons from "./components/CardButtons";

const PopupCard = ({ id }) => {
  const { data } = useContext(PanelContext);
  const style = sotaqueStyles[data[id].sotaque] || defaultStyle;

  return (
    <div className="card">
      <CardTitle id={id} data={data} style = {style} />
      <CardImage data={data}/>
      <CardButtons id={id} data={data} style={style}/>
    </div>
  );
};

export default PopupCard;