import { members } from "../../../../data";
import Card from "../../../Card";

export const TeamSection = () => (
  <div>
    {Object.values(members).map((section) => (
      <div key={section.title} className="equipe-section">
        <h3>{section.title}</h3>
        <ul
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {section.personal.map((person) => (
            <li key={person.name}>
              <Card
                imageSrc={person.imgUrl}
                nomePessoa={person.name}
                tituloPessoa={person.degree}
                link={person.link} // Adicione esta linha
              />
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);
