import {
  Leticia,
  Fellipe,
  Ruth,
  David,
  Hannah,
  Paulo,
  Francisco,
  Jandir,
  Juliana,
  Lais,
  Luis,
  Marcio,
  Raquel,
  Avatar,
} from "./assets/images/equipe";

import {
  Baixada,
  Matraca,
  CostaDeMao,
  Orquestra,
  Alternativo,
  Zabumba,
  BaixadaSmall,
  MatracaSmall,
  CostaDeMaoSmall,
  OrquestraSmall,
  AlternativoSmall,
  ZabumbaSmall,
} from "./assets/images/sotaques";

import { Banner } from "./assets/images";
import { MaracaCyan, MaracaGreen, MaracaPink, MaracaPurple, MaracaRed, MaracaYellow } from "./assets/images/markers";

export const members = {
  coordenators: {
    title: "Coordenação Geral",
    personal: [
      {
        name: "Letícia Cardoso",
        degree: "Profª. Drª",
        imgUrl: Leticia,
      },
    ],
  },
  consultants: {
    title: "Consultores",
    personal: [
      {
        name: "Jandir Gonçalves",
        degree: "Pesquisador",
        imgUrl: Jandir,
      },
      {
        name: "Raquel Noronha",
        degree: "Profª. Drª",
        imgUrl: Raquel,
      },
      {
        name: "Márcio Monteiro",
        degree: "Profª. Dr.",
        imgUrl: Marcio,
      },
      {
        name: "Francisco Silva",
        degree: "Profª. Dr.",
        imgUrl: Francisco,
      },
      {
        name: "David Bouças",
        degree: "Profª. Dr.",
        imgUrl: David,
      },
    ],
  },
  researchers: {
    title: "Pesquisadores",
    personal: [
      {
        name: "Luis Laurindo",
        degree: "Pesquisador",
        imgUrl: Luis,
      },
      {
        name: "Juliana Ribeiro",
        degree: "Pesquisadora",
        imgUrl: Juliana,
      },
      {
        name: "Hannah Letícia",
        degree: "Pesquisadora",
        imgUrl: Hannah,
      },
      {
        name: "Fellipe Reis",
        degree: "Pesquisador",
        imgUrl: Fellipe,
      },
      {
        name: "Ruth Barbosa",
        degree: "Pesquisadora",
        imgUrl: Ruth,
      },
      {
        name: "Laís Costa",
        degree: "Pesquisadora",
        imgUrl: Lais,
      },
    ],
  },
  designers: {
    title: "Design",
    personal: [
      {
        name: "Anderson Ribeiro",
        degree: "Designer",
        imgUrl: Avatar,
      },
      {
        name: "Juliana Ribeiro",
        degree: "Designer",
        imgUrl: Juliana,
      },
    ],
  },
  developers: {
    title: "Desenvolvimento do Site",
    personal: [
      {
        name: "Paulo Marques",
        degree: "Desenvolvedor Web",
        imgUrl: Paulo,
        link: "https://www.paulomarques.dev/"
      },
    ],
  },
};

const matraca = 'rgba(227, 6, 19, 1)'; // vermelho
const costaDeMao = 'rgba(0, 221, 184, 1)'; // roxo
const alternativo = 'rgba(105, 36, 118, 1)'; // roxo
const zabumba = 'rgba(238, 217, 23, 1)'; // amarelo
const orquestra = 'rgba(232, 71, 130, 1)'; // rosa
const baixada = 'rgba(65, 172, 61, 1)'; // verde

export {matraca, costaDeMao, alternativo, zabumba, orquestra, baixada};

export const sotaques = {
  title:
    "Estilos ou ritmos que constituem formas de tocar, dançar e apresentar o bumba meu boi, segundo a região de origem, mas também de acordo com as apropriações populares. Adotamos a categoria sotaque de acordo com a convenção usada pelos boieiros.",
  subtitle: "Conheça mais sobre os sotaques:",
  content:
    "Este é o começo de uma caminhada que busca levar ao alcance de todos um pouco do universo de riquezas culturais que o bumba meu boi pode oferecer. Como primeiro passo, apresentamos os sotaques e seus grupos, assim como se reconhecem e se apresentam, respeitando as identidades que construíram e que carregam com tanto orgulho. Embora alguns boieiros não se vejam pertencentes aos sotaques existentes, isso só demonstra como a brincadeira popular continua a crescer seguindo seus próprios caminhos, às vezes únicos e inéditos. O bumba meu boi não é resultado de classificações, mas da criatividade de quem o carrega no coração.",
  cards: [
    {
      name: "Matraca",
      description: "– ou sotaque da Ilha, provém da região de São Luís. Tem como elementos rítmicos os pandeirões (de couro ou nylon) e as matracas, peças de madeira que emitem som estridente. Fazem “arrastão” por onde passam por reunir muitos admiradores, que colaboram na percussão do batalhão. Tem como destaque: caboclos-de-pena e rajados.",
      color: "white",
      backgroundColor: matraca,
      image: Matraca,
    },
    {
      name: "Baixada",
      description: "– ou sotaque de Pindaré, município da Baixada Ocidental Maranhense. Caracteriza-se pelo som cadenciado reunindo instrumentos percussivos como pandeiros, caixas, tambores-onça, maracás e pequenas matracas. O destaque do sotaque é o Cazumba ou Cazumbá, personagem místico com aparência animalesca.",
      color: "white",
      backgroundColor: baixada,
      image: Baixada,
    },
    {
      name: "Costa de Mão",
      description: "– origina-se no município de Cururupu, no Litoral Ocidental Maranhense. Recebe essa denominação pela forma como os pandeiros são tocados, com a costa da mão. De ritmo lento, também usam os tambores-onça, maracás e pandeiros. Tem como destaques: vaqueiros campeadores e de cordão, tapuias.",
      color: "#692476",
      backgroundColor: costaDeMao,
      image: CostaDeMao,
    },
    {
      name: "Orquestra",
      description: "– surgiu na região do Rio Munim, em municípios como Rosário, Icatu e Axixá. Com o ritmo acelerado e toadas melódicas, é marcado pelos instrumentos de sopro e de corda, como saxofone, clarinete, flautas e banjos, que se misturam aos maracás e tambores. Tem como destaques: Índias, índios e vaqueiros.",
      color: "white",
      backgroundColor: orquestra,
      image: Orquestra,
    },
    {
      name: "Alternativo",
      description: "– grupos ligados a uma lógica mais urbana, de criação mais recente, autodeclarados alternativos por realizarem experimentações estéticas inspiradas em outros formatos de bumba-boi ou mesmo associados a outras dinâmicas culturais. Usam instrumentos de cordas, de sopro, além da percussão. Tem como destaques: bailarinos que encenam vários personagens.",
      color: "white",
      backgroundColor: alternativo,
      image: Alternativo,
    },
    {
      name: "Zabumba",
      description: "– ou sotaque de Guimarães, município do Litoral Ocidental Maranhense. As zabumbas constituem grandes tambores cobertos em couro, tocados em ritmo acelerado, apoiados em forquilhas. Também são usados os pandeirinhos ou tamborinhos, esquentados no calor da fogueira, junto com as zabumbas. Como destaques: rajados, campeadores e tapuias.",
      color: "#e04e12",
      backgroundColor: zabumba,
      image: Zabumba,
    },
  ],
};

export const bumbaBoi = {
  content: [
    "O complexo cultural do bumba meu boi, no Maranhão também chamado bumba-boi ou somente boi, faz a alegoria de um boi manipulado por uma pessoa que é o miolo.",
    "Junto dele, costumam dançar vários personagens misturados numa divertida roda, onde se vê artesanato, dança, mitos, música e teatralidade numa grande festa.",
    "No Maranhão, aproximadamente 450 comunidades são unidas por fortes laços criados pela cultura do bumba-boi. Concentram-se na Ilha de São Luís para os festejos juninos, nessa brincadeira popular que une devoção e prazer, compromissos de fé e de contrato: ao “brincar boi” os brincantes pagam promessas a santos e entidades, mas também negociam sua arte com o turismo e o mercado; vivem momentos de espetáculo e de confraternização.",
    "A festa é um ritual de resistência que traz alegria ao cotidiano de dificuldades do povo maranhense, com diversão, mas também com crítica social."
  ]
};

// Em data.js
export const introductionData = {
  paragraphs: [
    "O <strong>Caminhos da Boiada</strong> é um projeto de pesquisa e extensão desenvolvido pelo Grupo de Estudos Culturais no Maranhão / GECULT-MA, da Universidade Federal do Maranhão. Em parceria com o projeto Economia Criativa do Nordeste, do SEBRAE-MA, tem o objetivo de mapear os grupos de bumba meu boi da grande São Luís e contribuir para o fortalecimento da cadeia produtiva deste patrimônio imaterial brasileiro.",
    "O mapeamento cultural por meio das tecnologias digitais é também uma oportunidade de expansão dos saberes e práticas tradicionais do bumba meu boi em dimensões midiática, econômica, turística e patrimonial.",
    "Tomamos emprestado o nome “Caminhos da Boiada” de uma rua do Centro da capital São Luís (atual Rua Manuel Jansen Ferreira), assim denominada por ser parte da rota feita pelo gado durante o século XIX e início do XX, rumo ao matadouro público.",
    "Até então, as manifestações de bumba-boi eram perseguidas na cidade, por conta de uma cultura escravista e preconceituosa dos poderosos, que proibiam as festividades de povos negros. Ao ganhar força, a partir da década de 1960, o bumba-boi conquistou novos caminhos, podendo dançar em bairros centrais. A caminhada não mais seria para o abate do gado, mas para as festividades do touro que morre e renasce todos os anos no imaginário popular. A metáfora dos caminhos da boiada pretende ser atualizada com objetivo de georreferenciar territórios criativos e tradicionais onde a manifestação cultural é realizada.",
    "É neste contexto de inovação e desenvolvimento que se faz necessário fomentar o registro, a difusão e a valorização dessa prática cultural que representa o principal traço de identidade e pertencimento para diversas comunidades de bumba meu boi no Maranhão.",
    "Este mapa apresenta <strong>{totalQuantity}</strong> terreiros de bumba meu boi situados na área metropolitana de São Luís, incluindo Raposa, São José de Ribamar e Paço do Lumiar. Numa primeira versão, disponibilizamos um mapa impresso com o QRcode, que encaminhava o usuário aos links para as sedes dos grupos, pelo celular. Esta versão, em site, oferece um mapa ainda mais interativo, com recursos de imagens e vídeos, além de informações sobre a localização e contatos dos grupos de bumba meu boi."
  ],
  totalQuantity: "{totalQuantity}", // Mantenha como placeholder
  bannerUrl: "https://www.instagram.com/mapacaminhosdaboiada/?igshid=MmJiY2I4NDBkZg%3D%3D",
  bannerImage: Banner, // Certifique-se de importar 'Banner' corretamente
};
// Em data.js

export const sotaqueData = {
  Matraca: { image: MatracaSmall, name: "Matraca" },
  Baixada: { image: BaixadaSmall, name: "Baixada" },
  "Costa de Mão": { image: CostaDeMaoSmall, name: "Costa de Mão" },
  Orquestra: { image: OrquestraSmall, name: "Orquestra" },
  Alternativo: { image: AlternativoSmall, name: "Alternativo" },
  Zabumba: { image: ZabumbaSmall, name: "Zabumba" },
};

export const sotaqueColors = {
  Matraca: "#e30613",
  Baixada: "#00bf36",
  "Costa de Mão": "#00ddb8",
  Orquestra: "#e84782",
  Alternativo: "#692476",
  Zabumba: "#eed917",
};

export const sotaqueStyles = {
  "Baixada": {
    imageURL: BaixadaSmall,
    colorIcon: MaracaGreen,
    colorBg: baixada,
    colorFont: "#fff",
  },
  "Zabumba": {
    imageURL: ZabumbaSmall,
    colorIcon: MaracaYellow,
    colorBg: zabumba,
    colorFont: "#e04e12"
  },
  "Orquestra": {
    imageURL: OrquestraSmall,
    colorIcon: MaracaPink,
    colorBg: orquestra,
    colorFont: "#fff",
  },
  "Matraca": {
    imageURL: MatracaSmall,
    colorIcon: MaracaRed,
    colorBg: matraca,
    colorFont: "#fff",
  },
  "Costa de Mão": {
    imageURL: CostaDeMaoSmall,
    colorIcon: MaracaCyan,
    colorBg: costaDeMao,
    colorFont: "#692476"
  },
  "Alternativo": {
    imageURL: AlternativoSmall,
    colorIcon: MaracaPurple,
    colorBg: alternativo,
    colorFont: "#fff",
  }
};

// Default caso não encontre o sotaque especificado
export const defaultStyle = {
  imageURL: AlternativoSmall,
  colorIcon: MaracaPurple,
  colorBg: alternativo
};

export const sotaqueOptions = [
  { value: 'Matraca', label: 'Matraca' },
  { value: 'Baixada', label: 'Baixada' },
  { value: 'Costa de Mão', label: 'Costa de Mão' },
  { value: 'Orquestra', label: 'Orquestra' },
  { value: 'Alternativo', label: 'Alternativo' },
  { value: 'Zabumba', label: 'Zabumba' },
];