import { useContext } from "react";
import { PanelContext } from "../../../../../../../context/PanelContext";
import { FaInfoCircle, FaInstagram, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import CardButton from "./CardButtons/CardButton";
import AdditionalInfo from "./CardButtons/AdditionalInfo";

const CardButtons = ({ id, data, style }) => {
  const { showAdditionalInfo, setShowAdditionalInfo } = useContext(PanelContext);

  const actionButtons = [
    {
      icon: <FaMapMarkerAlt />,
      onClick: () => {
        const destination = `${data[id].latitude},${data[id].longitude}`;
        window.open(`https://www.google.com/maps/dir//${destination}`, "_blank");
      }
    },
    {
      icon: <FaInfoCircle />,
      onClick: () => setShowAdditionalInfo(!showAdditionalInfo),
    },
    data[id].telefone && {
      icon: <FaPhone />,
      onClick: () => window.open(`tel:${data[id].telefone}`, "_self"),
    },
    data[id].instagram && {
      icon: <FaInstagram />,
      onClick: () => window.open(`https://instagram.com/${data[id].instagram.replace("@", "")}`, "_blank"),
    }
  ].filter(Boolean);

  return (
    <div className="buttons buttons-container">
      <div className="buttons glass" style={{ backgroundColor: style.colorBg }}>
        {actionButtons.map((button, index) => (
          <CardButton
            key={index}
            icon={button.icon}
            onClick={button.onClick}
            style={{ color: style.colorFont }}
          />
        ))}
      </div>
      {showAdditionalInfo && <AdditionalInfo data={data[id]} style={style} />}
    </div>
  );
};

export default CardButtons;