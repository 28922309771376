// AuthContext.js
import React, { createContext, useEffect, useReducer, useMemo } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  currentUser: null,
};

const init = () => {
  try {
    return {
      currentUser: JSON.parse(localStorage.getItem("user")) || null,
    };
  } catch (error) {
    console.error("Error reading 'user' from localStorage:", error);
    return INITIAL_STATE;
  }
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE, init);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);

  const contextValue = useMemo(() => {
    return { currentUser: state.currentUser, dispatch };
  }, [state.currentUser, dispatch]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
