import React, { useRef } from 'react';

const SearchBar = ({ setSearchTerm, inputValue, setInputValue }) => {
  const searchDebounceTimeout = useRef(null);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    clearTimeout(searchDebounceTimeout.current);
    searchDebounceTimeout.current = setTimeout(() => {
      setSearchTerm(value);
    }, 300); // 1 segundo de espera
  };

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Procurar..."
        value={inputValue}
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default SearchBar;
