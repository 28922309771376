import { FaPlay } from "react-icons/fa";

export const CardSotaque = ({ card, handlePlayClick }) => (
  <div
    className="info-sotaque"
    style={{ backgroundColor: card.backgroundColor, color: card.color }}
  >
    <div
      style={{ position: "relative", display: "inline-flex", padding: "4px" }}
    >
      <img
        className="clickable-image"
        src={card.image}
        alt={card.name}
        height={120}
        width={80}
        onClick={() => handlePlayClick(card.name)}
      />
      <button className="playButton" onClick={() => handlePlayClick(card.name)}>
        <FaPlay style={{ fontSize: "48px" }} />
      </button>
    </div>
    <div>
      <p style={{ fontSize: "13px", textAlign: "left", fontWeight: "600" }}>
        {card.description}
      </p>
    </div>
  </div>
);