import { useMapEvents } from 'react-leaflet';
import { useContext, useEffect } from 'react';
import { PanelContext } from '../../context/PanelContext';

const ManualLocationHandler = () => {

  const { manualLocation, setLocation, setManualLocation } = useContext(PanelContext);

  const map = useMapEvents({
    contextmenu: (e) => {
      if (manualLocation) {
        handleRightClick(e);
      }
    },
  });

  useEffect(() => {
    const container = map.getContainer();

    container.addEventListener("contextmenu", handleContextMenu);

    function handleContextMenu(e) {
      if (manualLocation) {
        e.preventDefault();
      }
    }

    return () => {
      container.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [map, manualLocation]);

  const handleRightClick = (e) => {
    setLocation({
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
    });
    alert('Localização encontrada corretamente!');
    setManualLocation(false);
  };

  return null;
};

export default ManualLocationHandler;