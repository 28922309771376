import React, { useContext } from "react";
import { PanelContext } from "../../../../../context/PanelContext";
import { Popup } from "react-leaflet";
// import L from "leaflet";
import PopupCard from "./components/PopupCard";

const CustomPopup = ({ id }) => {
  const { setPopupImages, setOpenedPopupId } = useContext(PanelContext);

  return (
    <Popup
      onClose={(event) => {
        if (event.target && event.target.className.includes("carousel-arrow")) {
          return;
        }
        setPopupImages([]);
        setOpenedPopupId(null);
      }}
      onOpen={() => {
        setOpenedPopupId(id);
      }}
      // autoPanPaddingTopLeft={L.point(400, 180)}
      // autoPanPaddingBottomRight={L.point(400, 90)}
    >
      <PopupCard id={id} />
    </Popup>
  );
};

export default CustomPopup;
