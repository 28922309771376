import React from 'react'
import { sotaqueColors } from '../../../../data';

const ItemList = ({
  data,
  filteredDataIds,
  handleListItemClick,
  openedPopupId,
}) => (
  <ul>
    {filteredDataIds.map((id) => (
      <li
        key={id}
        onClick={() => handleListItemClick(id)}
        className={id === openedPopupId ? "activeListItem" : ""}
        style={{
          borderLeft: `4px solid ${sotaqueColors[data[id].sotaque]}`,
          borderRight: `4px solid ${sotaqueColors[data[id].sotaque]}`,
          paddingLeft: 8,
        }}
      >
        {`${data[id].codigo}. ${data[id].name}`}
      </li>
    ))}
  </ul>
);

export default ItemList