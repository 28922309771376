import React, { useEffect, useState, useCallback } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import './styles.css';
import firebaseDb from "../../config/firebase"; // Make sure to import your firebase config
import { useParams } from 'react-router-dom';
// import { FaChevronDown, FaCheck, FaTimes } from 'react-icons/fa';
import { ContactItem } from "./ContactItem";

const AdminPage = () => {
  const { id } = useParams();
  const [visibleDetails, setVisibleDetails] = useState(null);
  const [pendingContacts, setPendingContacts] = useState({});
  const [mainContacts, setMainContacts] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const fetchData = useCallback(async () => {
    const snapshot = await firebaseDb.child("contacts").get();
    const data = snapshot.val();

    if (id && data[id]) {

      // Retrieve image URL
      const storageRef = firebase.storage().ref(`images/pendingImages/${data[id].codigo}.webp`);
      storageRef.getDownloadURL()
        .then((url) => {

        })
        .catch((error) => {
          console.error("Error retrieving image URL:", error);
        });

    } else {
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  useEffect(() => {
    firebaseDb.child("pendingContacts").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setPendingContacts({ ...snapshot.val() });
      } else {
        setPendingContacts({});
      }
    });

    return () => {
      setPendingContacts({});
    };
  }, []);

  useEffect(() => {
    firebaseDb.child("contacts").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setMainContacts({ ...snapshot.val() });
      } else {
        setMainContacts({});
      }
    });

    return () => {
      setMainContacts({});
    };
  }, []);

  const openModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setCurrentImage(null);
    setShowModal(false);
  };
  

  const declineContact = async (key) => {
    const declinedContact = pendingContacts[key];

    // Delete the images from the pendingImages folder
    if (declinedContact.imageUrls) {
      for (const imageUrl of declinedContact.imageUrls) {
        const imageName = decodeURIComponent(imageUrl.split('/').pop().split('?')[0]).replace("pendingImages/", "");
        const pendingImageRef = firebase
          .storage()
          .ref(`pendingImages/${imageName}`);
        await pendingImageRef.delete();
      }
    }

    // Remove the contact from the pendingContacts collection
    firebaseDb.child("pendingContacts").child(key).remove();
  };


  const approveContact = async (key) => {
    const approvedContact = pendingContacts[key];
    delete pendingContacts[key];

    // Find the highest "codigo" in the main collection
    let highestCodigo = 0;
    for (const contactKey in mainContacts) {
      const codigo = parseInt(mainContacts[contactKey].codigo, 10);
      if (codigo > highestCodigo) {
        highestCodigo = codigo;
      }
    }

    // Calculate the next available "codigo"
    const nextCodigo = highestCodigo + 1;

    // Move the pending item to the main collection with the next available "codigo"
    const updatedContact = { ...approvedContact, codigo: nextCodigo };

    // Move the images from the pendingImages folder to the images folder
    if (approvedContact.imageUrls) {
      const newImageUrls = [];

      for (const [index, imageUrl] of approvedContact.imageUrls.entries()) {
        const imageName = `${nextCodigo}-${index + 1}`; // New image name format
        const fileExtension = imageUrl.split('.').pop().split('?')[0]; // Get the file extension
        const imageRef = firebase.storage().ref(`images/${nextCodigo}/${imageName}.${fileExtension}`);

        // Download the pending image
        const imageBlob = await (await fetch(imageUrl)).blob();

        // Upload the image to the new location
        await imageRef.put(imageBlob);

        // Get the new image URL
        const newImageUrl = await imageRef.getDownloadURL();

        newImageUrls.push(newImageUrl);

        // Delete the image from the pendingImages folder
        const pendingImageName = decodeURIComponent(imageUrl.split('/').pop().split('?')[0]).replace("pendingImages/", "");
        const pendingImageRef = firebase
          .storage()
          .ref(`pendingImages/${pendingImageName}`);
        await pendingImageRef.delete();
      }

      // Update the 'imageUrls' field in the contact data
      updatedContact.imageUrls = newImageUrls;
    }

    // Update the contact in the main collection
    firebaseDb.child(`contacts/${key}`).set(updatedContact);
    firebaseDb.child("pendingContacts").child(key).remove();
  };

  const toggleCollapsible = (key) => {
    if (visibleDetails === key) {
      setVisibleDetails(null);
    } else {
      setVisibleDetails(key);
    }
  };  

  return (
    <div className="form-container">
      <h1>Página Admin - Aprovações pendentes</h1>
      <ul>
        {Object.entries(pendingContacts).map(([key, contact], index) => (
          <ContactItem
            key={key}  // chave React
            index={index}  // índice do array
            contact={contact}
            visibleDetails={visibleDetails}
            toggleCollapsible={() => toggleCollapsible(index)} // Use index aqui
            approveContact={() => approveContact(key)}
            declineContact={() => declineContact(key)}
            showModal={showModal}
            setShowModal={setShowModal}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
            openModal={openModal}
            closeModal={closeModal}
          />
        ))}
      </ul>
    </div>
  );
}

export default AdminPage;
