function getTitleFontSize(text) {
  const baseFontSize = 14; // Set the base font size you'd like to use
  const maxCharacters = 27; // Set the maximum number of characters you'd like to fit in one line
  const minFontSize = 12; // Set the minimum font size allowed

  const adjustedFontSize = Math.min(baseFontSize, baseFontSize * maxCharacters / text.length);
  const shouldTruncate = adjustedFontSize <= minFontSize;

  const truncatedText = shouldTruncate ? truncateByWords(text, maxCharacters) : text;

  return {
    fontSize: shouldTruncate ? minFontSize : adjustedFontSize,
    truncate: shouldTruncate,
    text: truncatedText,
  };
}

function truncateByWords(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }

  const truncatedText = text.substr(0, maxLength);
  const lastSpaceIndex = truncatedText.lastIndexOf(' ');

  if (lastSpaceIndex === -1) {
    return truncatedText + '...';
  }

  const remainingWords = text.substr(lastSpaceIndex + 1);
  const remainingLength = maxLength - lastSpaceIndex;

  if (remainingWords.length > remainingLength) {
    return truncatedText.substr(0, lastSpaceIndex) + '...';
  } else {
    return truncatedText.substr(0, lastSpaceIndex) + ' ' + remainingWords;
  }
}

const isTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};


const adjustTooltipPosition = (left, top, width, height) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  let adjustedLeft = left;
  let adjustedTop = top;

  if (left + width > windowWidth) {
    adjustedLeft = windowWidth - width - 10; // Add some padding
  }

  if (top + height > windowHeight) {
    adjustedTop = windowHeight - height - 10; // Add some padding
  }

  return {
    left: adjustedLeft,
    top: adjustedTop,
  };
};

const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export { getTitleFontSize, isTouchDevice, adjustTooltipPosition, removeAccents };