import React from 'react'

const AdditionalInfo = ({ data, style }) => {
  const additionalInfoItems = [
    {
      label: "Representante",
      value: data.representante,
    },
    {
      label: "Endereço",
      value: data.sobre,
    },
    {
      label: "Telefone",
      value: data.telefone,
    },
  ];

  return (
    <div className="additional-info" style={{ color: style.colorFont, backgroundColor: style.colorBg, padding: "8px", fontFamily: 'Montserrat' }}>
      {additionalInfoItems.map((item, index) => 
        item.value && (
          <p key={index}>
            <strong>{item.label}:</strong> {item.value}
          </p>
        )
      )}
    </div>
  );
};

export default AdditionalInfo