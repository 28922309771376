import React, { useContext } from "react";
import { PanelContext } from "../../context/PanelContext.jsx";
import Footer from "../../components/Footer";
import FooterButton from "./components/FooterButton.jsx";
import LeafletMap from "./components/LeafletMap.jsx";
import { CollaboratePanel, FilterPanel, ListPanel, InfoPanel } from "../../components/Panels";
import "leaflet/dist/leaflet.css";
import "../../App.css";
import "./styles.css";

const Map = () => {
  const { showFilter, showList, showInfo, showCollaborate, showFooter } = useContext(PanelContext);

  return (
    <div>
      <LeafletMap />
      {showFilter && <FilterPanel />}
      {showInfo && <InfoPanel />}
      {showList && <ListPanel />}
      {showCollaborate && <CollaboratePanel />}
      {showFooter && <Footer />}
      <FooterButton />
    </div>
  );
};

export default Map;