import React, { useContext } from "react";
import { PanelContext } from "../../../context/PanelContext";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "leaflet/dist/leaflet.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles.css";
import "../../../App.css";

const FooterButton = () => {
  const { showFooter, handleShowFooter } =
    useContext(PanelContext);

  const footerPadding = showFooter ? "90px" : "5px";

  return (
    <button
      onClick={handleShowFooter}
      style={{
        position: "absolute",
        bottom: footerPadding,
        zIndex: 1002,
        cursor: "pointer",
      }}
    >
      {showFooter ? (
        <FaChevronDown></FaChevronDown>
      ) : (
        <FaChevronUp></FaChevronUp>
      )}
    </button>
  );
};

export default FooterButton;