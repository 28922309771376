import React, { useContext } from 'react'
import { PanelContext } from '../../../../context/PanelContext';
import { FaPlus } from 'react-icons/fa';


// ImageUploader.jsx
const ImageUploader = () => {
    const {
      imagePreviewUrls,
      uploadedFiles,
      setUploadedFiles,
      setImagePreviewUrls,
    } = useContext(PanelContext);
  
    const handleImageChange = (e, index) => {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      let newUploadedFiles = [...uploadedFiles];
      newUploadedFiles[index] = file;
      setUploadedFiles(newUploadedFiles);
  
      reader.onloadend = () => {
        let newImagePreviewUrls = [...imagePreviewUrls];
        newImagePreviewUrls[index] = reader.result;
        setImagePreviewUrls(newImagePreviewUrls);
      };
  
      reader.readAsDataURL(file);
    };
  
    return (
      <div className="form-buttons-container">
        <div className="form-image-grid">
          {imagePreviewUrls.map((previewUrl, index) => (
            <div className="form-image-preview" key={index}>
              {previewUrl && (
                <img src={previewUrl} alt={`uploaded preview ${index + 1}`} />
              )}
              {uploadedFiles[index] ? (
                <button
                  className="delete-image-button-collaborate"
                  onClick={(e) => {
                    e.preventDefault(); // Isso vai impedir que o evento seja propagado ao formulário
  
                    // Remove a imagem da lista uploadedFiles
                    let newUploadedFiles = [...uploadedFiles];
                    newUploadedFiles[index] = null;
                    setUploadedFiles(newUploadedFiles);
  
                    // Remove a imagem da lista imagePreviewUrls
                    let newImagePreviewUrls = [...imagePreviewUrls];
                    newImagePreviewUrls[index] = null;
                    setImagePreviewUrls(newImagePreviewUrls);
                  }}
                >
                  &times;
                </button>
              ) : index === 0 || uploadedFiles[index - 1] ? (
                <label htmlFor={`image-${index}`} className="custom-file-upload">
                  <FaPlus />
                </label>
              ) : (
                <div className="custom-file-upload-disabled">
                  <FaPlus />
                </div>
              )}
              <input
                type="file"
                id={`image-${index}`}
                name={`image-${index}`}
                onChange={(e) => handleImageChange(e, index)}
                style={{ display: "none" }}
                disabled={!(index === 0 || uploadedFiles[index - 1])}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

export default ImageUploader