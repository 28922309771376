// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import 'firebase/compat/database';
import 'firebase/compat/auth';
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "react-leaflet-map-45ae5.firebaseapp.com",
  databaseURL: "https://react-leaflet-map-45ae5-default-rtdb.firebaseio.com",
  projectId: "react-leaflet-map-45ae5",
  storageBucket: "react-leaflet-map-45ae5.appspot.com",
  messagingSenderId: "700010711894",
  appId: "1:700010711894:web:597da1e354d87eaab6e4f7"
};

// Initialize Firebase
const fireDb = firebase.initializeApp(firebaseConfig);
export default fireDb.database().ref();
export const auth = getAuth();