import React, { useState } from "react";
import CollaborateForm from "./components/CollaborateForm.jsx";
import PanelHeader from "../components/PanelHeader.jsx";

const CollaboratePanel = () => {

  const tooltip = useState({
    visible: false,
    content: "",
    top: 0,
    left: 0,
  });

  return (
    <div className="collaborate-panel">
      <PanelHeader title="Colabore com o mapa" panelName="collaborate" />
      <div className="info-container-text">
        <p>
          O <strong>Caminhos da Boiada</strong> é uma plataforma colaborativa,
          qualquer brincante pode cadastrar seu grupo de bumba meu boi e fazer
          parte do mapa. Quanto mais grupos mapeados, mais estaremos
          contribuindo para o reconhecimento dessa prática cultural e fomento de
          políticas públicas para o setor.
        </p>
        <p>
          Se você tiver dúvida ou sugestão, escreva pra gente!
          <br />
          caminhosdaboiada@gmail.com
        </p>

        <CollaborateForm />
      </div>
      <div
        className="custom-tooltip"
        style={{
          top: tooltip.top,
          left: tooltip.left,
          display: tooltip.visible ? "block" : "none",
        }}
      >
        {tooltip.content}
      </div>
    </div>
  );
};

export default CollaboratePanel;