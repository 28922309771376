import React, { useContext } from "react";
import { PanelContext } from "../../../context/PanelContext";
import { MapContainer, TileLayer } from "react-leaflet";
import ManualLocationHandler from "../../../components/ManualLocationHandler";
import MapMarker from "./components/MapMarker";
import ModalImages from "./ModalImages";
import "leaflet/dist/leaflet.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles.css";
import "../../../App.css";

const LeafletMap = () => {
  const { mapRef, filteredMarkers, viewImage } = useContext(PanelContext);
  const centerMap = [-2.5483629, -44.1899462];
  const limitMap = [
    [-10.95, -48.99],
    [2.45, -41.8],
  ];

  const mapConfig = {
    mapContainerConfig: {
      ref: mapRef,
      whenCreated: (map) => {
        mapRef.current = map;
      },
      center: centerMap,
      zoom: 11.5,
      scrollWheelZoom: true,
      dragging: viewImage === null,
      doubleClickZoom: false,
      closePopupOnClick: false,
      maxBounds: limitMap,
      zoomControl: false,
    },
    tileConfig: {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
  };

  return (
    <MapContainer {...mapConfig.mapContainerConfig}>
      <ManualLocationHandler />
      <TileLayer {...mapConfig.tileConfig} />
      {filteredMarkers.map((id) => (
        <MapMarker key={id} id={id} />
      ))}
      {viewImage && <ModalImages />}
    </MapContainer>
  );
};

export default LeafletMap;