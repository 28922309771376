import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { PanelProvider } from "./context/PanelContext";
import Modal from "react-modal";

const container = document.getElementById("root");
const root = createRoot(container);

Modal.setAppElement(container);

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <PanelProvider>
        <App />
      </PanelProvider>
    </AuthContextProvider>
  </React.StrictMode>
);