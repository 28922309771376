import React, { useState, useEffect } from 'react';
import firebaseDb from '../../config/firebase.js';
import { Link } from 'react-router-dom';
import './styles.css';
import { toast } from 'react-toastify';
import firebase from "firebase/compat/app";
import 'firebase/storage';
import { Edit, Delete } from '../../assets/icons/'

const Home = () => {

  const [data, setData] = useState({});

  useEffect(() => {
    firebaseDb.child("contacts").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData(snapshot.val());
      } else {
        setData({});
      }
    });
  
    return () => {
      setData({});
    };
  }, []);
  

  const onDelete = (id) => {
    if (window.confirm("Você tem certeza que deseja remover esse registro?")) {
      firebaseDb
        .child(`contacts/${id}`)
        .once("value", (snapshot) => {
          const contactData = snapshot.val();
          const { imageUrls } = contactData;
  
          if (imageUrls && imageUrls.length > 0) {
            const storage = firebase.storage();
            const deletePromises = imageUrls.map((imageUrl) => {
              const desertRef = storage.refFromURL(imageUrl);
              return desertRef.delete();
            });
  
            Promise.all(deletePromises)
              .then(() => {
                console.log("Images deleted successfully");
              })
              .catch((error) => {
                console.log("Error deleting images:", error);
              })
              .finally(() => {
                firebaseDb.child(`contacts/${id}`).remove((err) => {
                  if (err) {
                    console.log(err);
                  } else {
                    toast.success("Registro removido com sucesso");
                  }
                });
              });
          } else {
            firebaseDb.child(`contacts/${id}`).remove((err) => {
              if (err) {
                console.log(err);
              } else {
                toast.success("Registro removido com sucesso");
              }
            });
          }
        })
        .catch((error) => {
          console.log("Error reading contact data:", error);
        });
    }
  };
  
      
  return (
    <div className="home">
      <div className="table-container">
        <table className="styled-table">
          <thead>
            <tr>
              <th>Cód</th>
              <th>Nome</th>
              <th>Sotaque</th>
              <th>Representante</th>
              <th>Link Instagram</th>
              <th>Endereço</th>
              {/* <th>Latitude</th>
              <th>Longitude</th> */}
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((id) => {
              return (
                <tr key={id}>
                  <th scope="row">{data[id].codigo}</th>
                  <td>{data[id].name}</td>
                  <td>{data[id].sotaque}</td>
                  <td>{data[id].representante}</td>
                  <td>{data[id].instagram}</td>
                  <td>{data[id].sobre}</td>
                  {/* <td>{data[id].latitude}</td>
                  <td>{data[id].longitude}</td> */}
                  <td style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                    <Link to={`/update/${id}`}>
                      <button className="btn btn-edit"><img src={Edit} alt={'editar'}/></button>
                    </Link>
                    <button
                      className="btn btn-delete"
                      onClick={() => onDelete(id)}
                    >
                      <img src={Delete} alt={'apagar'} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Home;