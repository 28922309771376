import React from 'react';
import LimparFiltrosIcon from "../../../assets/icons/filter-off.svg";

const ClearFilters = ({ onClear, isVisible }) => {
  if (!isVisible) {
    return null; // Não renderiza o botão se não deve ser visível
  }

  return (
    <button className="clear-filters-button" onClick={onClear}>
      <img src={LimparFiltrosIcon} alt="Limpar filtros" width="16" height="16" />
    </button>
  );
};

export default ClearFilters;