// colorMapping.js
const colors = {
  "Baixada": '#00bf36',
  "Zabumba": '#eed917',
  "Orquestra": '#e84782',
  "Matraca": '#e30613',
  "Costa de Mão": '#00ddb8',
  "Alternativo": '#692476'
};

export const handleColor = (color) => colors[color] || '';
