import React from 'react'

const CardButton = ({ icon, onClick, style }) => {
  return (
    <button
      style={{ ...style }}
      onClick={onClick}
    >
      {icon}
    </button>
  );
};

export default CardButton