import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import firebaseDb from "../../config/firebase.js";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { FaPlus } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const initialState = {
  codigo: "",
  name: "",
  sotaque: "",
  latitude: "",
  longitude: "",
  representante: "",
  instagram: "",
  telefone: "",
  sobre: "",
  imageUrls: [],
};

const FormInput = ({ label, name, value, onChange }) => (
  <div className="formItem">
    <label htmlFor={name}>{label}:</label>
    <input
      type="text"
      id={name}
      name={name}
      placeholder={`${label}...`}
      value={value || ""}
      onChange={onChange}
      className="add-form__input"
    />
  </div>
);

const Header = ({ goToNext, goToPrevious, id }) => {
  return (
    <div className="form-title">
      <button onClick={goToPrevious}>
        <FaArrowLeft />
      </button>
      <h2>{id ? "Atualizar Registro" : "Novo registro"}</h2>
      <button onClick={goToNext}>
        <FaArrowRight />
      </button>
    </div>
  );
};

const AddEdit = () => {
  const [state, setState] = useState(initialState);
  const history = useNavigate();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imagePreviewUrls, setImagePreviewUrls] = useState(
    Array(15).fill(null)
  );
  const [uploadedFiles, setUploadedFiles] = useState(Array(15).fill(null));
  const [regularImageUrls, setRegularImageUrls] = useState(
    Array(15).fill(null)
  );
  const [currentImage, setCurrentImage] = useState(imagePreviewUrls[0]);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const openModal = (thumbUrl, regularUrl) => {
    setCurrentImage(regularUrl || thumbUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setCurrentImage("");
    setShowModal(false);
  };

  const nextImage = () => {
    const currentIndex = regularImageUrls.indexOf(currentImage);

    // Calcula o próximo índice, e volta para 0 se for a última imagem
    const nextIndex = (currentIndex + 1) % regularImageUrls.filter(Boolean).length;

    // Atualiza a imagem atual
    setCurrentImage(regularImageUrls[nextIndex]);
  };

  const prevImage = () => {
    const currentIndex = regularImageUrls.indexOf(currentImage);

    // Calcula o índice anterior, e vai para a última imagem se for a primeira
    const prevIndex = (currentIndex - 1 + regularImageUrls.filter(Boolean).length) % regularImageUrls.filter(Boolean).length;

    // Atualiza a imagem atual
    setCurrentImage(regularImageUrls[prevIndex]);
  };

  const debounce = useCallback((func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  }, []);

  const checkExistingCodigo = useMemo(
    () =>
      debounce(async (codigoToCheck) => {
        const snapshot = await firebaseDb.child("contacts").get();
        const data = snapshot.val();
        for (const key in data) {
          if (data[key].codigo === codigoToCheck) {
            return true;
          }
        }
        return false;
      }, 300),
    [debounce]
  );

  const formInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
      if (name === "codigo") {
        checkExistingCodigo(value);
      }
    },
    [checkExistingCodigo]
  );

  const renderFormInputs = () => {
    const inputNames = ["codigo", "name", "sotaque", "representante", "instagram", "sobre", "telefone", "latitude", "longitude"];
    const inputLabels = ["Código", "Nome", "Sotaque:", "Representante", "Instagram", "Endereço", "Telefone", "Latitude", "Longitude"];

    return (
      <>
        {inputNames.map((name, index) => {
          if (name === "sotaque") {
            return (
              <div key={name} className="form-select">
                <label htmlFor={name}>{inputLabels[index]}</label>
                <select
                  id={name}
                  name={name}
                  value={state[name]}
                  onChange={formInputChange}
                  required
                >
                  <option value="">Selecione um sotaque</option>
                  <option value="Matraca">Matraca</option>
                  <option value="Baixada">Baixada</option>
                  <option value="Costa de Mão">Costa de Mão</option>
                  <option value="Orquestra">Orquestra</option>
                  <option value="Alternativo">Alternativo</option>
                  <option value="Zabumba">Zabumba</option>
                </select>
              </div>
            );
          } else {
            return (
              <FormInput
                key={name}
                label={inputLabels[index]}
                name={name}
                value={state[name]}
                onChange={formInputChange}
              />
            );
          }
        })}
        <input
          type="text"
          placeholder="Cole o link do Google Maps aqui"
          onChange={handleMapUrlChange}
        />
        <button className="botao-coordenadas" onClick={extractCoordinates}>
          Extrair Coordenadas
        </button>
      </>
    );
  };

  const { codigo, name, sotaque, latitude, longitude } = state;

  const handleDeleteImage = async (index) => {
    const shouldDelete = window.confirm(
      "Tem certeza que deseja deletar esta imagem?"
    );

    if (!shouldDelete) {
      return;
    }

    const storageRef = firebase.storage().ref();
    const imagesRef = storageRef.child("images");

    const allImageUrls = [...uploadedFiles, ...state.imageUrls].filter(
      (url) => url !== null && url !== undefined
    );

    const imageToDelete = allImageUrls[index];

    if (imageToDelete) {
      const imagePath = imageToDelete.name
        ? `${codigo}/${imageToDelete.name}`
        : decodeURIComponent(
          new URL(imageToDelete).pathname.split("/").pop()
        ).replace(/^images\//, ""); // Remove 'images/' from the beginning
      const imageToDeleteRef = imagesRef.child(imagePath);
      await imageToDeleteRef.delete();
    }

    let newStateImageUrls = state.imageUrls.filter((url) => url !== undefined);
    newStateImageUrls.splice(index, 1);

    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(index, 1, null);
    setUploadedFiles(newUploadedFiles);

    const newImagePreviewUrls = [...imagePreviewUrls];
    newImagePreviewUrls.splice(index, 1, null);
    setImagePreviewUrls(newImagePreviewUrls);

    // Create a separate variable to store the updated state
    let updatedStateImageUrls = [...newStateImageUrls];

    for (let i = index; i < allImageUrls.length - 1; i++) {
      const oldImage = allImageUrls[i + 1];
      const oldImageName = decodeURIComponent(
        new URL(oldImage).pathname.split("/").pop()
      ).replace(/^images\//, ""); // Remove 'images/' from the beginning
      const oldImageRef = imagesRef.child(oldImageName);

      const newImageNumber = i + 1;
      const newImageName = `${codigo}-${newImageNumber}.${oldImageName
        .split(".")
        .pop()}`;
      const newImageRef = imagesRef.child(newImageName);

      const oldImageUrl = await oldImageRef.getDownloadURL();
      const response = await fetch(oldImageUrl);
      const blob = await response.blob();
      await newImageRef.put(blob);

      await oldImageRef.delete();

      updatedStateImageUrls[i] = await newImageRef.getDownloadURL();
      newImagePreviewUrls[i] = URL.createObjectURL(blob);
    }

    // Update the state once at the end of the loop
    await setState({ ...state, imageUrls: updatedStateImageUrls });
    setImagePreviewUrls(newImagePreviewUrls);
  };

  const goToNext = useCallback(async () => {
    const snapshot = await firebaseDb
      .child("contacts")
      .orderByKey()
      .startAfter(id)
      .limitToFirst(1)
      .get();
    const data = snapshot.val();
    if (data) {
      const nextId = Object.keys(data)[0];
      history(`/update/${nextId}`);
    }
  }, [id, history]);

  const goToPrevious = useCallback(async () => {
    const snapshot = await firebaseDb
      .child("contacts")
      .orderByKey()
      .endBefore(id)
      .limitToLast(1)
      .get();
    const data = snapshot.val();
    if (data) {
      const previousId = Object.keys(data)[0];
      history(`/update/${previousId}`);
    }
  }, [id, history]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const snapshot = await firebaseDb.child("contacts").get();
      const data = snapshot.val();

      if (id && data[id]) {
        const initialData = { ...data[id], imageUrls: [] };

        const storageRef = firebase.storage().ref();
        const imagesRef = storageRef.child(`images/${data[id].codigo}`);

        const res = await imagesRef.listAll();

        // Separate thumb and regular images
        const thumbItems = res.items.filter(
          (item) =>
            item.name.endsWith("-thumb.webp") && !item.name.includes("-small")
        );
        const regularItems = res.items.filter(
          (item) =>
            !item.name.endsWith("-thumb.webp") && !item.name.includes("-small")
        );

        // Sort them by name to keep the order
        thumbItems.sort((a, b) => a.name.localeCompare(b.name));
        regularItems.sort((a, b) => a.name.localeCompare(b.name));

        // Initialize thumbURLs array with null values to represent the empty spaces
        let thumbURLs = Array(15).fill(null);

        // Fetching all thumb URLs first
        const fetchedThumbURLs = await Promise.all(
          thumbItems.map((item) => item.getDownloadURL())
        );

        // Novo código para buscar as imagens normais
        let regularURLs = Array(15).fill(null);

        // If a thumb URL does not exist for a particular index, try to get the regular image URL
        const fetchedRegularURLs = await Promise.all(
          regularItems.map((item) => item.getDownloadURL())
        );

        // Preenche thumbURLs e regularURLs
        for (let i = 0; i < thumbItems.length; i++) {
          thumbURLs[i] = fetchedThumbURLs[i];
        }
        for (let i = 0; i < regularItems.length; i++) {
          regularURLs[i] = fetchedRegularURLs[i];
        }

        // Insert regular URLs only if there is no corresponding thumb
        for (let i = 0; i < regularItems.length; i++) {
          const regularName = regularItems[i].name;
          const correspondingThumbIndex = thumbItems.findIndex(
            (thumbItem) =>
              thumbItem.name === regularName.replace(".webp", "-thumb.webp")
          );

          if (correspondingThumbIndex === -1) {
            thumbURLs[i] = fetchedRegularURLs[i];
          }
        }

        setState((prevState) => ({
          ...prevState,
          ...initialData,
          imageUrls: thumbURLs,
        }));
        setImagePreviewUrls(thumbURLs)
        setRegularImageUrls(regularURLs);
      } else {
        setState({ ...initialState });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  const handleImageChange = (e, index) => {
    e.preventDefault();

    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.onloadend = () => {
        const newUploadedFiles = [...uploadedFiles];
        newUploadedFiles[index] = file;
        setUploadedFiles(newUploadedFiles);

        const newImagePreviewUrls = [...imagePreviewUrls];
        newImagePreviewUrls[index] = reader.result;
        setImagePreviewUrls(newImagePreviewUrls);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Iniciando o envio
    if (!codigo || !name || !sotaque || !latitude || !longitude) {
      toast.error("Por favor, preencha todos os campos obrigatórios!");
      return;
    }

    // Check if a record with the same codigo exists
    if (!id) {
      const isExistingCodigo = await checkExistingCodigo(codigo);
      if (isExistingCodigo) {
        toast.error("Já existe um grupo com este código!");
        return;
      }
    } else {
      const snapshot = await firebaseDb.child(`contacts/${id}`).get();
      const currentRecord = snapshot.val();

      if (currentRecord.codigo !== codigo) {
        const isExistingCodigo = await checkExistingCodigo(codigo);
        if (isExistingCodigo) {
          toast.error("Já existe um grupo com este código!");
          return;
        }
      }
    }

    let existingFotos = [];

    const storageRef = firebase.storage().ref();
    const imagesRef = storageRef.child(`images/${codigo}`); // Mudança aqui

    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];

      if (file) {
        const fileExtension = file.name.split(".").pop();
        // Aqui estamos adicionando o código e o índice da imagem
        const newImageNumber = i + 1;
        const newImageName = `${codigo}-${newImageNumber}.${fileExtension}`; // Modificado aqui

        const newImageRef = imagesRef.child(newImageName);
        await newImageRef.put(file);
        const url = await newImageRef.getDownloadURL();
        existingFotos.push(url);
      } else if (state.imageUrls && state.imageUrls[i]) {
        existingFotos.push(state.imageUrls[i]);
      }
    }

    const updatedState = {
      ...state,
      imageUrls: existingFotos,
    };

    if (!id) {
      const newKey = firebaseDb.child("contacts").push().key;
      firebaseDb.child(`contacts/${newKey}`).set(updatedState, (err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Grupo adicionado com sucesso!");
        }
      });
      setTimeout(() => history("/"), 500);
    } else {
      firebaseDb.child(`/contacts/${id}`).set(updatedState, (err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Grupo atualizado com sucesso!");
        }
      });
      // setTimeout(() => history("/"), 500);
    }
  };

  const extractCoordinates = (e) => {
    e.preventDefault();
    const url = new URL(state.mapUrl);
    const llParam = url.searchParams.get("ll");
    if (llParam) {
      const [latitude, longitude] = llParam.split(",");
      setState((prevState) => ({
        ...prevState,
        latitude,
        longitude,
      }));
    }
    toast.success("Coordenadas extraídas com sucesso");
    setIsSubmitting(false); // Envio concluído
  };

  const handleMapUrlChange = (e) => {
    const mapUrl = e.target.value;
    setState((prevState) => ({
      ...prevState,
      mapUrl,
    }));
  };

  return (
    <div className="form-container">
      <Header goToNext={goToNext} goToPrevious={goToPrevious} id={id} />
      {isLoading ? (
        <div className="loading">Carregando...</div>
      ) : (
        <div className="add-edit">
          <form
            className="add-edit__form"
            onSubmit={(e) =>
              handleSubmit(
                e,
                state,
                setState,
                history,
                id,
                uploadedFiles,
                imagePreviewUrls
              )
            }
          >
            <div className="form-data">
              <div className="form-left">{renderFormInputs()}</div>
              <div className="form-right">
                <div
                  className="formItem"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label
                    htmlFor="fotos"
                    style={{ marginBottom: "0.5rem", justifyContent: "center" }}
                  >
                    Imagens:{" "}
                    {
                      imagePreviewUrls.filter(Boolean).length
                    }
                  </label>
                  <div className="form-image-grid">
                    {imagePreviewUrls.map((thumbUrl, index) => (
                      <div className="form-image-preview" key={index}>
                        {(thumbUrl || state.imageUrls[index]) && (
                          <button
                            className="delete-image-button"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteImage(index);
                            }}
                          >
                            &times;
                          </button>
                        )}
                        <img
                          src={
                            thumbUrl
                              ? thumbUrl
                              : state.imageUrls[index]
                                ? state.imageUrls[index]
                                : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                          }
                          alt={`uploaded preview ${index + 1}`}
                          onClick={() =>
                            openModal(thumbUrl, regularImageUrls[index])
                          }
                        // Adicionado aqui
                        />

                        {uploadedFiles[index] ||
                          state.imageUrls[index] ? null : index === 0 ||
                            uploadedFiles[index - 1] ||
                            state.imageUrls[index - 1] ? (
                          <label
                            htmlFor={`image-${index}`}
                            className="form-image-label centered"
                          >
                            <FaPlus />
                          </label>
                        ) : (
                          <span className="custom-file-upload-disabled">
                            {/* <FaPlus /> */}
                          </span>
                        )}
                        <input
                          type="file"
                          id={`image-${index}`}
                          name={`image-${index}`}
                          onChange={(e) => handleImageChange(e, index)}
                          style={{ display: "none" }}
                          disabled={
                            !(
                              index === 0 ||
                              uploadedFiles[index - 1] ||
                              state.imageUrls[index - 1]
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-button">
              <button
                id="edit-submit-button"
                className={`add-edit__button ${isSubmitting ? "button-disabled" : "button-active"}`}
                type="submit"
                disabled={isSubmitting} // Desabilita o botão durante o envio
              >
                {isSubmitting ? "Atualizando..." : (id ? "Atualizar" : "Adicionar")}
              </button>
            </div>
          </form>
        </div>
      )}
      {showModal && (
        <div className="modal">
          <span className="close-button" onClick={closeModal}>
            &times;
          </span>
          <button onClick={prevImage} className="navigate-button prev-button">
            &lt;
          </button>
          <button onClick={nextImage} className="navigate-button next-button">
            &gt;
          </button>
          <img className="modal-content" src={currentImage} alt="modal" />
          <div className="image-order" style={{ textAlign: "center" }}>
            {`${regularImageUrls.indexOf(currentImage) + 1}/${regularImageUrls.filter(Boolean).length
              }`}
          </div>
        </div>
      )}
    </div>
  );
};
export default AddEdit;