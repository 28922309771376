import React from 'react'

const FormField = ({ label, name, type, value, onChange, options, required }) => {
    const renderField = () => {
      switch (type) {
        case 'text':
        case 'email':
          return <input type={type} id={name} name={name} value={value} onChange={onChange} required={required} />;
        case 'select':
          return (
            <select id={name} name={name} value={value} onChange={onChange} required={required}>
              <option value="">Selecione um sotaque</option>
              {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
          );
        default:
          return <input type="text" id={name} name={name} value={value} onChange={onChange} required={required} />;
      }
    };
  
    return (
      <div className="form-field">
        <label htmlFor={name}>{label}</label>
        {renderField()}
      </div>
    );
  };

export default FormField