import React, { useContext } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { PanelContext } from '../../../context/PanelContext';

const ModalImages = () => {

  const { setViewImage, fullSizeImages, selectedImageIndex, setSelectedImageIndex, enableDragging } = useContext(PanelContext);

  return (
    <div onClick={(e) => e.stopPropagation()} className="modal-container">
            <div
              style={{
                position: "relative",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button
                className="button-close-modal"
                onClick={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  setViewImage(null);
                  enableDragging();
                }}
              >
                x
              </button>

              <Carousel
                selectedItem={selectedImageIndex}
                showArrows={true}
                onChange={(index) => setSelectedImageIndex(index)}
                key={fullSizeImages.join(",")}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickHandler(e);
                      }}
                      title={label}
                      className="carousel-arrow carousel-arrow-prev"
                    >
                      {"<"}
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickHandler(e);
                      }}
                      title={label}
                      className="carousel-arrow carousel-arrow-next"
                    >
                      {">"}
                    </button>
                  )
                }
                showStatus={false}
                showThumbs={false}
                showIndicators={true}
                dynamicHeight={true}
                swipeable={false}
                className="custom-carousel"
              >
                {fullSizeImages.map((imageUrl, index) => (
                  <div key={index} className="carousel-image-container">
                    <img
                      src={imageUrl}
                      alt={`boi-${index}`}
                      className="carousel-image"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
  )
}

export default ModalImages