import React from 'react'
import { getTitleFontSize } from '../../../../../../../utils';

const CardTitle = ({ id, data, style }) => {
  const titleStyle = getTitleFontSize(`${data[id].codigo}. ${data[id].name}`);
  let { colorBg, colorFont, imageURL } = style;

  return (
    <div className="avatar glass" style={{ backgroundColor: colorBg }}>
      <div className="avatar-text">
        <h2
          className={titleStyle.truncate ? "truncate" : ""}
          style={{
            color: colorFont,
            fontSize: titleStyle.fontSize + "px",
            maxWidth: "100%",
            fontFamily: "Montserrat",
            fontWeight: "bold",
          }}
          title={`${data[id].name}`}
        >
          {titleStyle.text}
        </h2>
      </div>
      <div className="sotaque-img">
        <img className="glass-2" src={imageURL} alt={data[id].name} />
      </div>
    </div>
  );
};

export default CardTitle