import React from 'react';
import { usePanel } from '../../../context/PanelContext';

const CloseButton = ({ panelName }) => {
  const { handleClosePanel } = usePanel();

  return (
    <button className="close-panel-button" onClick={() => handleClosePanel(panelName)}>
      <span>&times;</span>
    </button>
  );
};

export default CloseButton;