import React, { useState, useContext } from "react";
import { PanelContext } from "../../../../context/PanelContext";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/database";
import firebaseDb from "../../../../config/firebase";
import ImageUploader from "./ImageUploader";
import FormField from "../../../Form/FormField";
import { sotaqueOptions } from "../../../../data";

const CollaborateForm = () => {
  const {
    location,
    uploadedFiles,
    setUploadedFiles,
    setImagePreviewUrls,
    handleGetLocation,
    handleManualLocation,
  } = useContext(PanelContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    sotaque: "",
    representante: "",
    instagram: "",
    endereco: "",
    telefone: "",
  });

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!location.latitude || !location.longitude) {
      alert("Por favor, selecione uma localização válida!");
      return;
    }

    setIsSubmitting(true); // Set isSubmitting to true here

    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());

    const timestamp = new Date().getTime();
    let imageUrls = [];
    let imageNames = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      if (uploadedFiles[i]) {
        const storageRef = firebase
          .storage()
          .ref(
            `pendingImages/${encodeURIComponent(
              formValues.email
            )}_${timestamp}-${i + 1}.webp`
          );
        const snapshot = await storageRef.put(uploadedFiles[i]);
        const downloadURL = await snapshot.ref.getDownloadURL();
        imageUrls.push(downloadURL.split("&")[0]);
        imageNames.push(
          `${encodeURIComponent(formValues.email)}_${timestamp}-${i + 1}.webp`
        );
      }
    }

    firebaseDb
      .child("pendingContacts")
      .push({ ...formValues, imageUrls, imageNames }, (err) => {
        if (err) {
          console.log("Error adding pending contact:", err);
          alert("Ocorreu um erro, tente novamente!");
        } else {
          alert("Cadastro realizado com sucesso!");
          event.target.reset();
          setImagePreviewUrls(Array(10).fill(null));
          setUploadedFiles(Array(10).fill(null));
        }
        setIsSubmitting(false); // Set isSubmitting back to false here
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        label="Nome"
        name="name"
        type="text"
        value={formState.name}
        onChange={handleChange}
        required
      />
      <FormField
        label="E-mail"
        name="email"
        type="email"
        value={formState.email}
        onChange={handleChange}
        required
      />
      <FormField
        label="Sotaque"
        name="sotaque"
        type="select"
        value={formState.sotaque}
        onChange={handleChange}
        required
        options={sotaqueOptions}
      />
      <FormField
        label="Representante"
        name="representante"
        type="text"
        value={formState.representante}
        onChange={handleChange}
        required
      />
      <FormField
        label="Instagram"
        name="instagram"
        type="text"
        value={formState.instagram}
        onChange={handleChange}
        required
      />
      <FormField
        label="Endereço"
        name="endereco"
        type="text"
        value={formState.endereco}
        onChange={handleChange}
      />
      <FormField
        label="Telefone"
        name="telefone"
        type="text"
        value={formState.telefone}
        onChange={handleChange}
      />

      <label htmlFor="sobre">Imagens:</label>
      <ImageUploader />
      <label htmlFor="sobre">Localização:</label>
      <div className="form-location-container">
        <span
          className="tooltip"
          data-tooltip="Identifica sua localização automaticamente. Você precisa estar com o serviço de localização ativo"
        >
          <button
            className="location"
            type="button"
            onClick={handleGetLocation}
          >
            Localização Atual
          </button>
        </span>
        <span
          className="tooltip"
          data-tooltip="Clique neste botão e em seguida com o botão direito do mouse em uma localização do mapa"
        >
          <button
            className="location"
            type="button"
            onClick={handleManualLocation}
          >
            Selecionar Manualmente
          </button>
        </span>
      </div>

      <button
        style={{ marginTop: "1rem" }}
        type="submit"
        className={`submit-button ${
          isSubmitting ? "submit-button-submitting" : ""
        }`}
        disabled={isSubmitting}
      >
        Enviar
      </button>
    </form>
  );
};

export default CollaborateForm;