import { CardSotaque } from "./IntroductionSection/components/CardSotaque";
import { sotaques } from "../../../../data";
import useSoundPlayer from "../../../../hooks/useSoundPlayer";

export const SotaquesSection = () => {
  const { playSound } = useSoundPlayer();
  // Função para lidar com o clique que toca o som
  const handlePlayClick = (sotaque) => {
    playSound(sotaque);
  };
  return (
    <div>
      <p>{sotaques.title}</p>
      <p style={{ textAlign: "center" }}>
        <strong>{sotaques.subtitle}</strong>
      </p>

      {sotaques.cards.map((card) => (
        <CardSotaque
          key={card.name}
          card={card}
          handlePlayClick={handlePlayClick}
        />
      ))}

      <p>{sotaques.content}</p>
    </div>
  );
};