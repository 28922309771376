import { useContext } from "react";
import { introductionData } from "../../../../../data";
import { PanelContext } from "../../../../../context/PanelContext";

export const IntroductionSection = () => {
  const { totalQuantity } = useContext(PanelContext);
  const quantity = totalQuantity ? totalQuantity : "Indisponível";
  console.log(quantity)

  // Função para processar o texto e substituir <strong> por componentes React
  const processParagraph = (text) => {
    const parts = text.split(/<strong>(.*?)<\/strong>/g);

    return parts.map((part, index) => {
      // Substituir {totalQuantity} por quantity em todas as partes
      const processedPart = part.replace("{totalQuantity}", quantity);

      if (index % 2 === 0) {
        // Partes não dentro de <strong>
        return processedPart;
      } else {
        // Partes dentro de <strong>
        return <strong key={index}>{processedPart}</strong>;
      }
    });
  };

  return (
    <div>
      {introductionData.paragraphs.map((paragraph, index) => (
        <p key={index}>{processParagraph(paragraph)}</p>
      ))}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <a
          href={introductionData.bannerUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <img src={introductionData.bannerImage} alt="banner" className="banner" />
        </a>
      </div>
    </div>
  );
};