import React from 'react';
import './styles.css';

import { Ufma, Sebrae, Fapema, PPgcom, Nida, Obeec, Gecult } from '../../assets/images/logos';

const Footer = () => {

  return (
      <div>
        <div className="footer">
          <div className='footer-apoio'>
            <div className='footer-title'>Apoio:</div>
            <div className='footer-images'>
              <img className='image-logo' src={Ufma} alt="ufma" height={'auto'} width={82} />
              <img className='image-logo' src={Sebrae} alt="ufma" height={'auto'} width={100} />
              <img className='image-logo' src={Fapema} alt="ufma" height={'auto'} width={120} />
              <img className='image-logo' src={PPgcom} alt="ufma" height={'auto'} width={100} />
              <img className='image-logo' src={Nida} alt="ufma" height={'auto'} width={100} />
              <img className='image-logo' src={Obeec} alt="ufma" height={'auto'} width={82} />
            </div>
          </div>
          <div className='footer-realizacao'>
            <div className='footer-title' style={{width: '100%', textAlign: 'left'}}>Realização:</div>
            <div className='footer-images'>
              <img className='image-logo' src={Gecult} alt="ufma" height={'auto'} width={82} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default Footer;
