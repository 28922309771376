import { useMemo } from "react";
import { removeAccents } from "../utils";

export const useFilteredData = (data, searchTerm, selectedSotaques) => {
    return useMemo(() => {
      if (!data) return [];
      return Object.keys(data).filter((id) => {
        const sotaque = data[id].sotaque;
        const name = removeAccents(data[id].name.toLowerCase());
        const normalizedSearchTerm = removeAccents(searchTerm.toLowerCase());
        return (
          (!selectedSotaques || selectedSotaques[sotaque]) &&
          (!searchTerm || name.includes(normalizedSearchTerm))
        );
      });
    }, [data, searchTerm, selectedSotaques]);
  };