import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { PanelContext } from "../../context/PanelContext";
import { useContext } from "react";

import { LogoCaminhos, Boi1, Boi2, Faixa2 } from "../../assets/images";

import Confetti from "react-confetti";

import { sotaqueColors } from "../../data";

const HeaderTop = ({animateOx}) => {

  return (
    <div className="header-top">
        <img className="image-logo" src={LogoCaminhos} alt="caminho" />
        <img
          className={`image-bois ${animateOx ? "animate-ox" : ""}`}
          src={Boi1}
          alt="boi1"
        />
        <img
          className={`image-bois-2 ${animateOx ? "animate-ox" : ""}`}
          src={Boi2}
          alt="boi2"
        />
        <img
          className="image-faixa"
          src={Faixa2}
          alt="faixa"
          width={500}
          height={4}
          style={{ position: "absolute", bottom: 0 }}
        />
      </div>
  )}

const Header = () => {
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const {
    showFilter,
    showList,
    showInfo,
    showCollaborate,
    handleOpenPanel,
    handleClosePanel,
    showConfetti,
    animateOx,
    selectedSotaques,
    numberOfPieces,
  } = useContext(PanelContext);
  const navitage = useNavigate();

  const [activeTab, setActiveTab] = useState("Home");
  const location = useLocation();

  const getFilteredColors = (selectedSotaques) => {
    const filteredSotaques = Object.keys(selectedSotaques).filter(
      (sotaque) => selectedSotaques[sotaque]
    );

    if (filteredSotaques.length === 0) {
      return Object.values(sotaqueColors);
    }

    return filteredSotaques.map((sotaque) => sotaqueColors[sotaque]);
  };

  const filteredColors = getFilteredColors(selectedSotaques);

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab("Map");
    } else if (location.pathname === "/add") {
      setActiveTab("AddContact");
    } else if (location.pathname === "/map") {
      setActiveTab("Map");
    }
  }, [location]);

  const handleLogout = (e) => {
    e.preventDefault();

    if (window.confirm("Deseja mesmo realizar o logout?")) {
      dispatch({ type: "LOGOUT" });
      navitage("/");
    }
  };

  return (
    <div className="header">
      <div className="confetti-container">
        {showConfetti && (
          <Confetti
            width={500}
            height={100}
            numberOfPieces={numberOfPieces}
            colors={filteredColors}
            initialVelocityY={{ min: -7.5, max: 7.5 }}
            initialVelocityX={{ min: -5.5, max: 5.5 }}
            friction={0.7}
            drawShape={(ctx) => {
              ctx.beginPath();

              // Swallowtailed dimensions (scaled down)
              const width = 12 * 0.66;
              const height = 8 * 0.66;
              const triangleWidth = 4 * 0.66;

              // Draw the Swallowtailed shape
              ctx.moveTo(0, 0);
              ctx.lineTo(width, 0);
              ctx.lineTo(width - triangleWidth, height / 2);
              ctx.lineTo(width, height);
              ctx.lineTo(0, height);
              ctx.closePath();
              // Fill the Swallowtailed shape
              ctx.fill();
            }}
            tweenDuration={100}
          />
        )}
      </div>
      <HeaderTop animateOx={animateOx} />
      <div className="header-right">
        <div className="header-buttons">
          <div className="header-buttons-row">
          <button
              className={`header-button ${showFilter ? "active" : ""}`}
              onClick={() => showFilter ? handleClosePanel("sotaqueFilter") : handleOpenPanel("sotaqueFilter")}
            >
              Filtrar
            </button>
            <button
              className={`header-button ${showList ? "active" : ""}`}
              onClick={() => showList ? handleClosePanel("markerList") : handleOpenPanel("markerList")}
            >
              Lista
            </button>
            <button
              className={`header-button ${showInfo ? "active" : ""}`}
              onClick={() => showInfo ? handleClosePanel("info") : handleOpenPanel("info")}
            >
              Sobre
            </button>
            <button
              className={`header-button ${showCollaborate ? "active" : ""}`}
              onClick={() => showCollaborate ? handleClosePanel("collaborate") : handleOpenPanel("collaborate")}
            >
              Colabore
            </button>
          </div>
          {currentUser ? (
            <div className="header-admin-buttons-row">
              <Link to="/cadastro">
                <p
                  className={`header-button ${
                    activeTab === "Home" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Home")}
                >
                  Cadastro
                </p>
              </Link>

              <Link to="/add">
                <p
                  className={`header-button ${
                    activeTab === "AddContact" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("AddContact")}
                >
                  Adicionar
                </p>
              </Link>
              <Link to="/map">
                <p
                  className={`header-button ${
                    activeTab === "Map" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Map")}
                >
                  Mapa
                </p>
              </Link>
              <Link to="/admin">
                <p
                  className={`header-button ${
                    activeTab === "Aprovar" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Aprovar")}
                >
                  Aprovar
                </p>
              </Link>
              <Link to="/map">
                <p className={`header-button logout`} onClick={handleLogout}>
                  Logout
                </p>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
