import React, { useContext, useState } from "react";
import { PanelContext } from "../../../context/PanelContext";
import { useFilteredData } from "../../../hooks/useFilteredData";
import SearchBar from "./components/SearchBar";
import ItemList from "./components/ItemList";
import PanelHeader from "../components/PanelHeader";
import ClearFilters from "../components/ClearFilters";

const ListPanel = () => {
  const {
    data,
    mapRef,
    markerRefs,
    fetchImages,
    setSearchTerm,
    searchTerm,
    openedPopupId,
    selectedSotaques,
  } = useContext(PanelContext);
  const filteredDataIds = useFilteredData(data, searchTerm, selectedSotaques);
  const [inputValue, setInputValue] = useState(searchTerm);

  const handleListItemClick = async (id) => {
    const codigo = data[id].codigo;
    const marker = markerRefs.current[id];
    const map = mapRef.current;
    if (!map || !marker) return;

    try {
      marker.openPopup();
      await fetchImages(codigo);
    } catch (error) {
      console.error("Erro ao buscar imagens:", error);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setInputValue("");
  };
  return (
    <div className="marker-list-panel">
      <PanelHeader title="Lista de Bumba-Bois" panelName="markerList" />
      <ClearFilters onClear={handleClearSearch} isVisible={!!searchTerm} />
      <SearchBar
        setSearchTerm={setSearchTerm}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
      <div className="info-container-text-lista">
        <ItemList
          data={data}
          filteredDataIds={filteredDataIds}
          handleListItemClick={handleListItemClick}
          openedPopupId={openedPopupId}
        />
      </div>
    </div>
  );
};

export default ListPanel;
