import { useContext } from "react";
import { PanelContext } from "../../../context/PanelContext.jsx";
import { SotaqueButton } from "../InfoPanel/components/IntroductionSection/components/SotaqueButton.jsx";
import { sotaqueData } from "../../../data.js";
import PanelHeader from "../components/PanelHeader.jsx";
import ClearFilters from "../components/ClearFilters.jsx";

const FilterPanel = () => {
  const {
    selectedSotaques,
    setSelectedSotaques,
  } = useContext(PanelContext);

  const handleSotaqueButtonClick = (key) => {
    setSelectedSotaques((prevSelectedSotaques) => {
      const isCurrentlySelected = prevSelectedSotaques[key];
      const totalSelected = Object.values(prevSelectedSotaques).filter(Boolean).length;
  
      // No primeiro clique, desmarcar todos os filtros, exceto o clicado
      if (totalSelected === Object.keys(sotaqueData).length) {
        return Object.keys(sotaqueData).reduce((state, sotaqueKey) => {
          state[sotaqueKey] = sotaqueKey === key;
          return state;
        }, {});
      }
  
      // Se o filtro atual está selecionado e é o único, resetar todos os filtros para true
      if (isCurrentlySelected && totalSelected === 1) {
        return Object.keys(sotaqueData).reduce((state, sotaqueKey) => {
          state[sotaqueKey] = true;
          return state;
        }, {});
      }
  
      // Adicionar ou remover o filtro clicado dos filtros selecionados
      return {
        ...prevSelectedSotaques,
        [key]: !prevSelectedSotaques[key]
      };
    });
  };

  const clearFilters = () => {
    const resetState = Object.keys(sotaqueData).reduce((state, sotaqueKey) => {
      state[sotaqueKey] = true;
      return state;
    }, {});
    setSelectedSotaques(resetState);
  };

  // Verifica se todos os filtros estão ativados
  const isFilterActive = Object.values(selectedSotaques).every((value) => value);

  return (
    <div
      className="sotaque-filter-container"
    >
      <PanelHeader title="Filtros" panelName="sotaqueFilter" />
      <ClearFilters onClear={clearFilters} isVisible={!isFilterActive} />
      <div className="combined-row">
        {Object.entries(sotaqueData).map(([key, { image, name }]) => (
          <SotaqueButton
            key={key}
            name={name}
            image={image}
            selected={selectedSotaques[key]}
            onClick={() => handleSotaqueButtonClick(key)}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterPanel;