// ContactItem.js
import React from 'react';
import { handleColor } from './colorMapping';
import { FaChevronDown, FaCheck, FaTimes } from 'react-icons/fa';

export const ContactItem = ({ contact, index, visibleDetails, toggleCollapsible, approveContact, declineContact, showModal, setShowModal, currentImage, setCurrentImage, openModal, closeModal }) => {

  const nextImage = () => {
    const currentIndex = contact.imageUrls.indexOf(currentImage);
    const nextIndex = (currentIndex + 1) % contact.imageUrls.length;
    setCurrentImage(contact.imageUrls[nextIndex]);
  };

  const prevImage = () => {
    const currentIndex = contact.imageUrls.indexOf(currentImage);
    const prevIndex = (currentIndex - 1 + contact.imageUrls.length) % contact.imageUrls.length;
    setCurrentImage(contact.imageUrls[prevIndex]);
  };

  return (

    <div className="contact-item">
      <div
        className={`collapsible${visibleDetails === index ? ' active' : ''}`}
        style={{ color: handleColor(contact.sotaque) }}
        onClick={() => toggleCollapsible(index)}
      >
        {index + 1}. {contact.name} - {contact.representante} {/* Add the index here */}
        <span className={`arrow-icon ${visibleDetails === index ? "rotate" : ""}`}>
          <FaChevronDown />
        </span>
      </div>
      <div className={`content ${visibleDetails === index ? "show" : ""}`}>
        <div className="details-top">
          <div className="details-info">
            <span><strong>Nome:</strong> {contact.name}</span>
            <span><strong>E-mail:</strong> {contact.email}</span>
            <span><strong>Sotaque:</strong> {contact.sotaque}</span>
            <span><strong>Representante:</strong> {contact.representante}</span>
            <span><strong>Instagram:</strong> {contact.instagram}</span>
            <span><strong>Telefone:</strong> {contact.telefone}</span>
          </div>
          <div className="details-images">
            <span><strong>Imagens:</strong></span>
            <div className="form-buttons-container">
              {/* <div className="form-image-preview">
                      <img src={contact.sobre ? contact.sobre : Caminho} alt="uploaded preview" />
                    </div> */}
              <div className="form-image-container">
                {/* Display the list of images */}
                {contact.imageUrls &&
                  contact.imageUrls.map((imageUrl, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={imageUrl}
                      alt={`${imgIndex + 1}`}
                      className="preview-image"
                      onClick={() => openModal(imageUrl)}  // Adicionado aqui
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="contact-buttons">
          <button
            className="btn-admin btn-approve"
            onClick={(e) => { e.stopPropagation(); approveContact(index); }}
          >
            <FaCheck />
          </button>
          <button
            className="btn-admin btn-decline"
            onClick={(e) => { e.stopPropagation(); declineContact(index); }}
          >
            <FaTimes />
          </button>
        </div>
      </div>
      {showModal && (
        <div className="modal">
          <span className="close-button" onClick={closeModal}>&times;</span>

          {/* Adicione os botões de navegação aqui */}
          <button onClick={prevImage} className="navigate-button prev-button">&lt;</button>
          <button onClick={nextImage} className="navigate-button next-button">&gt;</button>

          <img className="modal-content" src={currentImage} alt='modal' />
        </div>
      )}

    </div>)
};
