// hooks/useSoundPlayer.js
import { Howl } from 'howler';

import {
  orquestraSound,
  baixadaSound,
  costaDeMaoSound,
  matracaSound,
  zabumbaSound,
  alternativoSound,
} from "../assets/sounds";

const useSoundPlayer = () => {

  const soundMap = {
    Matraca: new Howl({ src: [matracaSound], volume: 0.75 }),
    Baixada: new Howl({ src: [baixadaSound], volume: 0.75 }),
    "Costa de Mão": new Howl({ src: [costaDeMaoSound], volume: 0.75 }),
    Orquestra: new Howl({ src: [orquestraSound], volume: 1 }),
    Alternativo: new Howl({ src: [alternativoSound], volume: 0.75 }),
    Zabumba: new Howl({ src: [zabumbaSound], volume: 0.75 }),
  };

  const playSound = (sotaque) => {
    // Parar todos os sons antes de tocar um novo
    Object.values(soundMap).forEach(sound => sound.stop());

    // Tocar o som selecionado
    soundMap[sotaque].play();
  };

  return { playSound };
};

export default useSoundPlayer;