import {
  IntroductionSection,
  TeamSection,
  BumbaBoiSection,
  SotaquesSection,
} from "../components/index.js";
import PanelHeader from "../components/PanelHeader.jsx";
import { useState } from "react";

export const tabsData = {
  Introduction: "Introdução",
  BumbaBoi: "O Bumba Boi",
  Sotaques: "Os Sotaques",
  Equipe: "A Equipe",
};

const InfoPanel = () => {
  const [activeTab, setActiveTab] = useState("Introduction");

  return (
    <div
      className="info-container-sobre"
    >
      <PanelHeader title="Mapeamento Cultural do Bumba meu boi" panelName="info" />

      <div className="tab-container">
        {Object.entries(tabsData).map(([tabKey, tabInfo]) => (
          <div
            key={tabKey}
            className={activeTab === tabKey ? "tab tab-active" : "tab"}
            onClick={() => setActiveTab(tabKey)}
          >
            {tabInfo}
          </div>
        ))}
      </div>
      <div className="info-container-text">
        {activeTab === "Introduction" && <IntroductionSection />}
        {activeTab === "BumbaBoi" && <BumbaBoiSection />}
        {activeTab === "Sotaques" && <SotaquesSection />}
        {activeTab === "Equipe" && <TeamSection />}
      </div>
    </div>
  );
};

export default InfoPanel;